html {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}
body {
	height: 100%;
	margin: 0;
	font-family: NotoSans, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.shipMentDetails {
	td {
		text-align: center !important;
	}
}
.mailLoader {
	position: absolute;
	top: 49px;
	left: 0;
	width: 100%;
	z-index: 999;
	height: calc(100% - 49px);
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
}
.MuiFormLabel-root {
	font-size: 0.8rem !important;
}
.text_disabled {
	background: #f1f1f1;

	.MuiInputLabel-root {
		transform: translate(14px, -5px) scale(0.75) !important;
	}
}
.text_normal {
	.MuiInputLabel-root {
		transform: translate(14px, -5px) scale(0.75) !important;
	}
}


.qs_row {
	td {
		padding: 0px !important;
		padding-right: 5px !important;
		padding-left: 5px !important;
		font-size: 12px !important;
		color: black;
		text-align: left !important;
	}
}