
@keyframes spinner1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner1 {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner1 1.5s linear infinite;
}

.spinner-container1 {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
}
