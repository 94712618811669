#root {
    height: 100%;
    width: 100%;
    font-size:12px;
}

.map-container {
    height: 100%;
    width: 100%;
}
.dashBoardTotal {
    background-color: #ecf5ff !important;
    border-color: #d9ecff !important;
    color: #409EFF !important;
}
.productCharges {
    border: 0.5px solid #2d9cdb !important;
    background-color: #00aeef0d !important;
    color: #2d9cdb !important;
    padding:4px 10px !important;
    align-items:center !important;
    border-radius:12px !important;
    height:24px !important;
    font-size:11px !important;
}
.dashBoardPrepared {
    background-color: #eaae39 !important;
    border-color: #eaae39 !important;
    color: #fff !important;
}
.dashBoardPrepared_qs {
    color: #eaae39 !important;
    font-size: 14px !important;
}
.dashBoardPrepared_qs1 {
    color: #eaae39 !important;
    font-size: 14px !important;
}
.dashBoardApproved_qs {
    color: #6fcf97 !important;
    font-size: 14px !important;
}
.dashBoardApproved {
    background-color: #6fcf97 !important;
    border-color: #6fcf97 !important;
    color: #fff !important;
}
.dashBoardDeclined_qs {
    color: #f45858 !important;
    font-size: 14px !important;
}
.dashBoardDeclined {
    background-color: #f45858 !important;
    border-color: #f45858 !important;
    color: #fff !important;
}
.dashBoardPending {
    background-color: #2d9cdb !important;
    border-color: #eaae39 !important;
    color: #fff !important;
}
.dashBoardCancled_qs {
    color: red !important;
    font-size: 14px !important;
}
.dashBoardPending_qs {
    color: #2d9cdb !important;
    font-size: 14px !important;
}
.dashBoardSuccess {
    background-color: #f0f9eb !important;
    border-color: #f0f9eb !important;
    color: #67c23a !important;
}
.dashBoardSuccess_qs {
    color: #f0f9eb !important;
    font-size: 12px !important;
}
span {
    font-size: 12px !important;
}
input {
    font-size: 12px !important;
}
button {
    font-size: 12px !important;
}
.font12 {
    font-size: 12px !important;
}
.td_gray {
    color: #606266 !important;
}
th {
    padding: 5px !important;
    font-size: 12px !important;
    color: #909399 !important;
    font-weight:bold !important;
}
tr:hover {
    background: #FFF !important;
}
tr:nth-child(even) {
    background: #FFF !important;
}

tr:nth-child(odd) {
    background: #FFF ;
}
td {
    padding: 5px !important;
    font-size: 12px !important;
    color: #909399;
    text-align: left !important;
}
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.qs_filter_btn {
    color: #848484 !important;
    height: 36px !important;
    border-radius: 3px !important;
    border: 1px solid #ccc !important;
    background: #fafafa !important;
    text-transform: none !important;
}
.MuiFormControlLabel-label {
 font-size:12px !important;
}
.MuiButtonBase-root {
    font-size: 12px !important;
    height: 27px;
}
.MuiButton-root {
    min-width: 43px !important;
    min-height: 27px !important;
}
.min_height_40_btn {
    min-height: 40px !important;
}
.plus_btn {
    color: black !important;
    background: #aee3f6 !important;
}
.minus_btn {
    color: black !important;
    background: #e4e4e4 !important;
}
.charges_details_btn {
    min-width: 28px !important;
    height: 28px !important;

}
.MuiSelect-select {
    font-size: 12px !important;
    padding: 5.5px 25px !important;
    padding-left:10px !important;
}
.font_16 {
 font-size:16px !important;
}
.charges_label {
    text-transform: none !important;
    color: black !important;
    font-weight:700 !important;
}
.text_normal {
}
.text_disabled {
    background: #f1f1f1;

}
.quotType_header {
    max-width: 8vw !important;
    min-width: 4vw;
}
.Cutomer_header {
    max-width: 6vw !important;
    min-width: 4vw;
}
.cost_btn_qs {
    background: #85ce61 !important;
    border-color: #85ce61 !important;
    color: #FFF !important;
    border-radius: 20px !important;
    width: 70px !important;
    height: 40px !important;
}
.import_export_dlg_lable {
    padding: 23px 0 22px 32px !important;
    background: #23408f !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    border-radius: 5px 5px 0 0 !important;
    color: #fff !important;
    width: 215px !important;
    height: 70px !important;
    text-transform: none !important;
}
.export::before {
    content: '';
    position: absolute;
    top: 12px;
    right: 13px;
    display: block;
    width: 12px;
    height: 12px;
    background: url(./arrow_export.svg) center no-repeat;
}
.import::before {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    content: '';
    position: absolute;
    top: 12px;
    right: 13px;
    display: block;
    width: 12px;
    height: 12px;
    background: url(./arrow_export.svg) center no-repeat;
}
.import_export_dlg_lable_disabled {
    padding: 23px 0 22px 32px !important;
    background: #23408f38 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    border-radius: 5px 5px 0 0 !important;
    color: #fff !important;
    width: 215px !important;
    height: 70px !important;
    text-transform: none !important;
}
.importExportBtn {
    display: block !important;
    width: 145px !important;
    margin: 0 auto !important;
    background: #00aeef !important;
    height: 44px !important;
    padding: 0 30.5px !important;
    border-radius: 22px !important;
    color: #fff !important;
}

.font_15 {
    font-size:15px !important;
}
