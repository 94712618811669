.login-main-container {
    width: 100%;
    height: 680px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-container {
        width: 360px;
        height: 380px;
        border: 1px solid rgb(167, 159, 159);
        background-color: #FFFFFF;
        border-radius: 10px;
        position: absolute;
        box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.14);

        .login-title {
            color: #00a0d1;
            margin-top: 20px;
            text-align: center;

            div {
                display: inline-block;
            }
        }

        

        .login-title-sub {
            // color:#00a0d1;
            color: #6b7375;
            font-weight: 400;
            font-size: 18px;
            letter-spacing: .5px;
            padding: 0px 5px;
        }

        .login_form {
            padding: 20px 35px 10px;

            .login-input {
                text-indent: 10px;
                height: 36px;
                width: 100%;
                min-width: 250px;
                margin-top: 5px;
                border: 1px solid rgba(0,0,0,0.12);
                border-radius: 8px;

                &:focus {
                    outline: none;
                }
            }

            .login-label {
                opacity: 0.75;
                color: #525E66;
                letter-spacing: 0.4px;

                &.password {
                    margin-top: 22px;
                }
            }

            .login-button {
                margin-top: 35px;
                min-width: 250px;
                width: 100%;
                height: 36px;
                border-radius: 8px;
                background: #1d84c9;
                color: #FFFFFF;
                font-family: Roboto;
                font-weight: 500;
                letter-spacing: 1.25px;
                text-align: center;
                border: 0px solid transparent;
                cursor: pointer;

                .btn-loading {
                    margin-left: 5px;
                    position: relative;
                    top: 5px;
                }

                .btn-label {
                    position: relative;
                    vertical-align: middle;
                    bottom: 1px;
                }
            }
        }

        .login-error {
            color: #f10a0a;
            position: relative;
            text-align: center;
            font-weight: 400;
        }
    }
}
.login-title-strong {
    background-image: url(/resources/logo.png);
    height: 78px;
    width: 311px;
    background-repeat: no-repeat;
    background-position: 50% 2%;
}
.main-title-strong {
    background-image: url(/resources/logo.png);
    height: 48px;
    width: 175px;
    float:left;
    background-repeat: no-repeat;
    background-position: 50% 2%;
}